/**
 * jQuery content slideshow
 * @name showslide
 * @author Dominik Kressler
 * @version 1.2.6b
 * @category jQuery plugin
 * @copyright (c) 2013
 *
 * Changelog:
 *
 * http://dev.euroweb-websites.de/slideshow/readme.html
*/
 (function($)
 {
 	$.fn.showslide = function(params)
 	{
		this.each(function(i,elm)
		{
			//Extending settings with data-attributes
			var settings = $.extend({}, $.fn.showslide.defaultSettings, $(elm).data());
			settings = $.extend({}, settings, params);
			settings.instance = $(elm);
			settings = $.fn.showslide.init(settings);
		});
		return this;
	};
	$.fn.showslide.init = function(settings)
	{
		//Extending settings with internal vars
		settings.id = 'id'+(new Date()).getTime();
		settings.cssID = settings.instance.attr('id');
		settings.items = settings.instance.children();
		settings.itemCount = settings.items.length;

		// settings.items.css({'transition-duration': settings.speed+'ms'});
		
		settings.direction = 1; // 1 = fwd | 0 = bwd
		
		settings.intid = null;
		settings.speed = parseInt(settings.speed);

		if(settings.randomize == true)
		{
			var itemarr = settings.items.toArray();

			for (var i = itemarr.length - 1; i > 0; i--) 
			{
				var j = Math.floor(Math.random() * (i + 1));
				var temp = itemarr[i];
				itemarr[i] = itemarr[j];
				itemarr[j] = temp;
			}
			
			settings.items = $(itemarr);
			settings.instance.html(settings.items);
		}

		if(settings.group > 0)
		{
			settings.group = parseInt(settings.group);
			settings.itemClass = 12 / settings.group+'';
			settings.itemClass = settings.itemClass.replace('.', '_');
			var tmpItems = settings.items.toArray();
			var max = Math.round(parseFloat(settings.items.length / settings.group));
			for(i=0;i<=max;i++)
			{
				start = (i + i )* (settings.group/2); 
				end = start + settings.group;
				$(tmpItems.slice(start, end)).wrapAll('<div class="slide row" />').addClass('col default-'+settings.itemClass);
			}
			settings.items = settings.instance.children();
			settings.itemCount = settings.items.length;
		}

		if(settings.useCSS == false || $.fn.showslide.browserSupport('transition') == false)
		{
			settings.useCSS = false;
			settings.instance.removeClass('fade').addClass('horizontal');
			$(settings.items).each(function(i)
			{
				if(i>0) $(this).fadeOut(0);
				$(this).css({top:0,left:0});
			});
		}

		if(settings.storage == true && localStorage.getItem(settings.cssID) != null)
		{
			settings.current = settings.selection = parseInt(localStorage.getItem(settings.cssID));
		}
		else settings.current = settings.selection = settings.startAt;

		//Prepare the DOM objects
		settings.instance.attr('data-id',settings.id);
		settings.items.addClass('slide');
		$(settings.items[settings.selection]).addClass('active');
		if(settings.navigation == true && settings.itemCount > 1) $.fn.showslide.buildNavigation(settings);

		if (typeof(settings.onStart) == 'function') settings.onStart();

		//Force into stretch mode
		if(settings.stretch) $.fn.showslide.stretch(settings);
		
		//Distribute css classnames
		settings = $.fn.showslide.elmSetter(settings);

		//Starting if playing = true
		if(settings.playing==true && settings.itemCount > 1) settings.intid = setInterval(function(){$.fn.showslide.slide(settings)}, settings.interval);

		$(settings.items).bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function() { settings = $.fn.showslide.elmSetter(settings); });
	};
	$.fn.showslide.slide = function(settings, direct)
	{
		settings.isSliding = true;

		//This is setting the current/next/prev if navigation isn't used
		if(typeof(settings.next) == 'undefined' && typeof(settings.prev) == 'undefined')
		{
			switch(settings.current)
			{
				case 0:
				settings.next = (settings.direction == 1) ? 1 : settings.itemCount-1;
				settings.prev = (settings.direction == 1) ? settings.itemCount-1 : 1;
				break;
				case settings.itemCount-1:
				settings.next = (settings.direction == 1) ? 0 : settings.current-1;
				settings.prev = (settings.direction == 1) ? settings.current-1 : 0;
				break;
				default:
				settings.next = (settings.direction == 1) ? settings.current+1 : settings.current-1;
				settings.prev = (settings.direction == 1) ? settings.current-1 : settings.current+1;
				break;
			}
		}

		if(settings.useCSS)
		{
			if (settings.direction == 1)
			{
				$(settings.items[settings.next]).removeClass('right').addClass('active animate');
				$(settings.items[settings.current]).removeClass('active').addClass('left animate');
			}
			else
			{
				$(settings.items[settings.next]).removeClass('left').addClass('active animate');
				$(settings.items[settings.current]).addClass('right animate').removeClass('active');
			}
		}
		else
		{
			if (settings.direction == 1)
			{
				$(settings.items[settings.next]).addClass('active ');
				$(settings.items[settings.current]).removeClass('active');
			}
			else
			{
				$(settings.items[settings.next]).addClass('active');
				$(settings.items[settings.current]).removeClass('active');
			}
			
			$(settings.items[settings.current]).fadeOut(settings.speed, 'linear');
			$(settings.items[settings.next]).stop().fadeIn(settings.speed, 'linear', function()
			{
				settings = $.fn.showslide.elmSetter(settings);
			});
		}

		settings.current = settings.next;
		delete settings.next; delete settings.prev;

		// active state for pagination
		if(settings.navigation == true)
		{
			$(settings.controls.pagination.nodes).each(function(){$(this).removeClass('active');});
			$(settings.controls.pagination.nodes[settings.current]).addClass('active');
		}

		if(settings.storage) localStorage.setItem(settings.cssID, settings.current);

		// getting from pause back to play
		if(settings.intid == null && settings.playing) settings.intid = setInterval(function(){$.fn.showslide.slide(settings)}, settings.interval);

		return this;
	};
	$.fn.showslide.elmSetter = function(settings)
	{
		if (typeof(settings.onNext) == 'function') settings.onNext();
		settings.isSliding = false;
		if (settings.direction === 1) $(settings.items).not($(settings.items[settings.current])).removeClass('left animate').addClass('right');
		if (settings.direction === 0) $(settings.items).not($(settings.items[settings.current])).removeClass('right animate').addClass('left');	

		return settings;
	};
	$.fn.showslide.buildNavigation = function(settings)
	{
		//capability to place the controls somewhere in the dom
		settings.navigationContainer = (settings.extraextra) ? $(settings.extraextra) : settings.instance;

		//create and add controls
		settings.controls = new Object();
		settings.controls.prevBut = $('<a class="showslidePrev showslideController">'+settings.prevText+'</a>');
		settings.controls.nextBut = $('<a class="showslideNext showslideController">'+settings.nextText+'</a>');
		var initStateText = (settings.playing) ? settings.pauseText : settings.playText;
		if(settings.hoverStop === false) settings.controls.playBut = $('<a class="showslideController showslidePlay '+settings.playing+'">'+initStateText+'</a>');
		for (var control in settings.controls) settings.navigationContainer.append(settings.controls[control]);

		//create and add pagination
		settings.controls.pagination = $('<ul class="showslideController showslideSelect" data-nav-for="'+settings.id+'"></ul>');
		if(settings.thumbnails) settings.controls.pagination.addClass('thumbnails');
		settings.navigationContainer.append(settings.controls.pagination);
		settings.controls.pagination.nodes = new Array();
		for(var i=0; i<settings.items.length;i++)
		{
			settings.controls.pagination.nodes[i] = $('<li data-slide="'+i+'">'+(i+1)+'</li>');
			settings.controls.pagination.append(settings.controls.pagination.nodes[i]);

			if(settings.thumbnails)
			{
				settings.controls.pagination.nodes[i].css({backgroundImage:'url('+$(settings.items[i]).find('img').attr('src')+')'});
			}
			settings.controls.pagination.nodes[i].click(function(e)
			{
				e.preventDefault();
				if(settings.current != $(this).attr('data-slide'))
				{
					window.clearInterval(settings.intid);
					settings.intid = null;
					settings.next = $(this).data('slide');
					settings.direction = (settings.next > settings.current) ? 1 : 0;
					settings = $.fn.showslide.elmSetter(settings);
					setTimeout(function(){$.fn.showslide.slide(settings);}, 50);
				}
			});
		}
		$(settings.controls.pagination.nodes[settings.selection]).addClass('active');

		//Events for the controls
		settings.controls.prevBut.click(function(e)
		{
			e.preventDefault();
			window.clearInterval(settings.intid);
			settings.intid = null;
			settings.direction = 0;
			settings = $.fn.showslide.elmSetter(settings);
			setTimeout(function(){$.fn.showslide.slide(settings);}, 50);
		});
		settings.controls.nextBut.click(function(e)
		{
			e.preventDefault();
			window.clearInterval(settings.intid);
			settings.intid = null;
			settings.direction = 1;
			settings = $.fn.showslide.elmSetter(settings);
			setTimeout(function(){$.fn.showslide.slide(settings);}, 50);
		});
		if(settings.hoverStop === true && settings.playing === true)
		{
			settings.instance.hover(function(e)
			{
				settings.intid = window.clearInterval(settings.intid);
				settings.intid = null;
				settings.playing = false;
			}, function(e)
			{
				settings.playing = true;
				settings.intid = setInterval(function(){$.fn.showslide.slide(settings)}, settings.interval);
			});
		}
		else if (typeof settings.controls.playBut != 'undefined')
		{
			settings.controls.playBut.click(function(e)
			{
				button = $(this);
				e.preventDefault();
				if(settings.playing)
				{
					settings.intid = window.clearInterval(settings.intid);
					settings.intid = null;

					settings.playing = false;
					button.removeClass('true');
					button.html(settings.playText);
					if (typeof(settings.onPause) == 'function') settings.onPause();
				}
				else
				{
					settings.playing = true;
					$.fn.showslide.slide(settings);
					button.addClass('true');
					button.html(settings.pauseText);
				}
			});
		}
		settings.instance.mouseover(function()
		{
			$(document).keydown(function(e)
			{
				switch(e.which)
				{
					case 37:// left
					settings.controls.prevBut.trigger('click');
					break;
					case 38:// up
					break;
					case 39:// right
					settings.controls.nextBut.trigger('click');
					break;
					case 40:// down
					break;
					default: return;
				}
				e.preventDefault();
			});			
		});	
	};
	$.fn.showslide.killControls = function(settings)
	{
		for (var key in settings.controls) 
		{
			settings.controls[key].remove();
		}
		settings.navigation = false;
	};
	$.fn.showslide.stretch = function(settings)
	{
		if(settings.stretch=='fullscreen')settings.instance.css({position:'fixed', width:'100%', height:'100%',margin:0,zIndex:0,top:0,left:0});
		if(settings.stretch)$(settings.items).find('img').css({position:'absolute'}).ruggedStretch();
	};
	$.fn.showslide.browserSupport = function(prop) 
	{
		var div = document.createElement('div'),  
		vendors = 'Khtml Ms O Moz Webkit'.split(' '),  
		len = vendors.length;  
		if (prop in div.style) return true;  
		prop = prop.replace(/^[a-z]/, function(val) 
		{  
			return val.toUpperCase();  
		});  
		while(len--) if (vendors[len] + prop in div.style) return true;    
		return false;  
	};
	$.fn.showslide.defaultSettings =
	{
		speed : 800,
		interval: 5000,
		navigation: false,
		prevText: 'previous',
		nextText: 'next',
		playText: 'play',
		pauseText: 'pause',
		extraextra: false,
		playing: true,
		useCSS: true,
		stretch: false,
		hoverStop: true,
		thumbnails: false,
		startAt: 0,
		storage: false,
		group: 0,
		randomize: false
	};
})(jQuery);

/**
 * jQuery image stretcher
 * @name rugged.stretch.js
 * @author Dominik Kressler
 * @version 1.1
 * @date October 16, 2013
 * @category jQuery plugin
 * @copyright (c) 2013 Dominik Kressler
 */
 (function($)
 {
 	$.fn.ruggedStretch = function(settings)
 	{
 		var settings = $.extend({}, $.fn.ruggedStretch.defaultSettings, settings);
 		settings.items = $(this);
 		settings.loader = Array();

 		$(window).resize(function() 
 		{
 			$.fn.ruggedStretch.init(settings);
 		});

 		$.fn.ruggedStretch.init(settings);
 	};
 	$.fn.ruggedStretch.init = function(settings)
 	{
 		$.each(settings.items, function(i,elm)
 		{
 			elm = $(elm);

 			var objImagePreloader = new Image();

 			elm.src = elm.attr('src');
 			
 			objImagePreloader.onload = function()
 			{
 				var imageWidth = objImagePreloader.width,
 				imageHeight = objImagePreloader.height,
 				windowWidth = elm.parent().width(),
 				windowHeight = elm.parent().height(),
 				verticalRatio = windowWidth / imageWidth,
 				horizontalRatio = windowHeight / imageHeight,
 				horizontalDiff = horizontalRatio * imageWidth,
 				verticalDiff = verticalRatio * imageHeight; 				

 				if(verticalDiff>windowHeight)
 				{
 					elm.css({width: windowWidth+'px', height: verticalDiff+'px', left:'50%', top:'50%', marginLeft:(windowWidth/2*-1)+'px', marginTop:(verticalDiff/2*-1)+'px'});
 				}
 				else
 				{
 					elm.css({width: horizontalDiff+'px', height: windowHeight+'px', left:'50%', top:'50%', marginLeft:(horizontalDiff/2*-1)+'px', marginTop:(windowHeight/2*-1)+'px'});
 				}
 				objImagePreloader.onload=function(){};
 			}
 			objImagePreloader.src = elm.src;

 			return elm;
 		});
		return settings;
	};
	$.fn.ruggedStretch.defaultSettings = {};
})(jQuery);